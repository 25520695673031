import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import { VerticalSpacer } from '~/utils/LSTVUtils';
import CardGrid from '~/components/CardGrid';
import LSTVLink from '../LSTVLink';
import { EventParams } from '~/globals/trackEvent';
import LSTVButton from '~/components/LSTVButton';
import { Media } from '~/styles/media';
import breakpoints from '~/globals/breakpoints';
import Typography from '~/atoms/Typography';

export const CardSectionStyle = styled.div`
  display: block;

  @media ${LSTVGlobals.UserDevice.isTablet} {
    padding: 0 0 40px 0;
  }

  @media ${LSTVGlobals.UserDevice.isMobile} {
    padding: 17px 0 49px 0;
    display: flex;
    flex-direction: column;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${breakpoints.isMobileOrTablet} {
    padding: 10px 5px 0 5px;
  }
`;

const HeadingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
`;

const SeeAllButton = styled(LSTVButton)`
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: white;
  color: ${LSTVGlobals.BLACK};
  border: 2px solid #000000;
  box-sizing: border-box;
  cursor: pointer;
`;

const DesktopSeeAllButton = styled(SeeAllButton)`
  width: 150px;
  height: 37px;
  border-radius: 20px;
`;

const MobileSeeAllButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MobileSeeAllButton = styled(SeeAllButton)`
  width: 275px;
  height: 56px;
  border-radius: 50px;
  margin-top: 30px;
  align-self: center;
`;

type Props = {
  data: any; //ICardCollection, AK: changed to any so it can be used by RecommendedVideos - better type later
  narrow?: boolean;
};

const CardSection = ({ data, narrow, ...props }: Props) => {
  // obtain base class properties to transfer to card grid and their cards.
  const parentProps = {};
  Object.keys(props)
    .filter((key) => {
      return key.startsWith('font') && key !== 'children';
    })
    .map((key) => {
      parentProps[key] = props[key];
    });

  const getCardGrid = (isMobileOnly: boolean, isMobile: boolean) => {
    let verbosity = isMobile
      ? LSTVGlobals.CONTENT_CARD_VERBOSITY_LEVEL_MEDIUM
      : LSTVGlobals.CONTENT_CARD_VERBOSITY_LEVEL_MAXIMUM;

    // no max verbosity for mobile + blog story
    if (isMobile && data.card_type === 'article') {
      verbosity = LSTVGlobals.CONTENT_CARD_VERBOSITY_LEVEL_MEDIUM;
    }

    const propSet = {
      ...parentProps,
      numCards: 4,
      content: data.content,
      cardType: data.card_type,
      name: data.header,
      verbosity,
      containerMode: isMobileOnly ? 'h-scroll' : 'grid',
      ...(narrow ? { numColumns: { xs: 1, sm: 2 } } : {}),
      ...data.content_options,
    };

    return <CardGrid {...propSet} />;
  };

  return (
    <EventParams section="cards" section_slug={data.slug} section_title={data.header}>
      <CardSectionStyle>
        <HeaderContainer>
          <HeadingsContainer>
            <Typography variant="h2" fontWeight="bold">
              {data.header}
            </Typography>
            {data.sub_header && <Typography variant="h4">{data.sub_header}</Typography>}
          </HeadingsContainer>

          <Media greaterThan="xs">
            <LSTVLink to={data.url}>
              <DesktopSeeAllButton eventAction="See all">See All</DesktopSeeAllButton>
            </LSTVLink>
          </Media>
        </HeaderContainer>

        <Media lessThan="xs">
          <VerticalSpacer space={25} />
        </Media>

        <Media greaterThanOrEqual="xs">
          <VerticalSpacer space={68} />
        </Media>

        <Media at="xs">{getCardGrid(true, true)}</Media>
        <Media at="sm">{getCardGrid(false, true)}</Media>
        <Media greaterThan="sm">{getCardGrid(false, false)}</Media>

        <Media at="xs">
          <MobileSeeAllButtonContainer>
            <LSTVLink to={data.url}>
              <MobileSeeAllButton eventAction="See all">See All</MobileSeeAllButton>
            </LSTVLink>
          </MobileSeeAllButtonContainer>
        </Media>
      </CardSectionStyle>
    </EventParams>
  );
};

export default CardSection;
